<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 v-title-cadastro">
      <span class="h5 font-weight-bold">Projeto: <span class="">{{ projeto.titulo }}</span></span>
    </v-card-title>
    <MasterDetail ref="masterDetail" formTitle="Cadastro demais dispêndios" :hasExportCSV="false" :hasExportXLSX="false"
      :opts="opts" :cols="cols" :canEdit="false" :canDelete="true" :hasNewButton="false"
      :hasDayFilter="true"
      :actionBarButtons="actionBarButtons" :contextOptions="contextOptions" :customResource="customResource">

      <DropdownCaptacao>
      </DropdownCaptacao>

    </MasterDetail>
  </v-card>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
//import FormModal from '@/components/form-modal.vue';

const tipoRubricasCamposAdicionaisEnum = [
  'Viagens e Diárias'
]

export default {
  // props: {},
  components: {
    MasterDetail,
    DropdownCaptacao: () => import('@/components/dropdown-captacao.vue'),
    // FormModal,
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      opts: {
        rubricas: [],
        documentos: [],
        projetos: [],
        fornecedores: [],
        descricaoRubricas: [],
        contrapartidas: [
          {
            id: '0',
            value: 'Não',
          },
          {
            id: '1',
            value: 'Sim',
          },
        ],
        equipesViagensEDiarias: [
          {
            id: 1,
            value: 'Não'
          },
          {
            id: 2,
            value: 'Sim'
          }
        ],
        tipoDespesasViagensEDiarias: [
          {
            id: 1,
            tipo: 'Alimentação',
          },
          {
            id: 2,
            tipo: 'Passagem',
          },
          {
            id: 3,
            tipo: 'Diária',
          },
          {
            id: 4,
            tipo: 'Deslocamento',
          },
        ]
      },
      formModalOpen: false,
      formModalValue: {},
      isDescricaoEditable: false,
      isVisibleNewFornecedorData: false,
      isVisibleViagensDiarias: false,
      projeto: {
        titulo: '',
      }
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons() {
      const actionBarButtons = [];
      const that = this;
      actionBarButtons.push({
        text: "Adicionar",
        icon: "mdi-plus-box-outline",
        show: true,
        action() {
          that.$router.push({ name: "cadastro-demais-dispendios", params: { id: that.projetoId } });
        }
      });

      return actionBarButtons.filter(({ show }) => show);
    },
    contextOptions() {
      const contextOptions = [];
      const that = this;
      contextOptions.push({
        name: "Editar",
        show: true,
        cb(row) {
          that.$router.push({ name: "edicao-demais-dispendios", params: { id: that.projetoId, dispendio: row.id } });
        }
      });

      return contextOptions.filter(({ show }) => show);
    },
    cols() {
      return [
        {
          key: 'projeto_id',
          name: 'Projeto',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'projetos', key: 'id', name: 'titulo' },
          rules: [{ rule: 'required' }],
          tooltip: true,
          truncate: true,
          hideInTable: true,
          hideInform: true,
          width: '250px',
        },
        {
          key: 'rubrica_id',
          name: 'Rubrica',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'rubricas', key: 'id', name: 'tipo' },
          width: '150px',
        },
        {
          key: 'pareamento_id',
          name: 'Descrições da Rubrica',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'descricaoRubricas', key: 'id', name: 'descricao' },
          tooltip: true,
          truncate: true,
          width: '150px',
        },
        {
          key: 'pareamento_id',
          name: 'Finalidade',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'descricaoRubricas', key: 'id', name: 'finalidade' },
          tooltip: true,
          truncate: true,
          width: '150px',
        },
        {
          key: 'documento_id',
          name: 'Tipo documento',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'documentos', key: 'id', name: 'tipo' },
          rules: [{ rule: 'required' }],
          colSize: 4,
        },
        {
          key: 'nro_documento',
          name: 'Nº Documento',
          type: this.$fieldTypes.TEXT,
          colSize: 3,
        },
        {
          key: 'valor',
          name: 'Valor',
          type: this.$fieldTypes.MONEY,
          colSize: 3,
          width: '150px',
        },
        {
          key: 'data_emissao',
          name: 'Data Emissão',
          type: this.$fieldTypes.DATE,
          colSize: 3,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'data_pagamento',
          name: 'Data Pagamento',
          type: this.$fieldTypes.DATE,
          colSize: 3,
        },
        {
          key: "contrapartida",
          name: "Contrapartida",
          colSize: 4,
          hideInTable: true,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: 0, nome: "Não" },
              { id: 1, nome: "Sim" },
            ],
            key: "id",
            name: "nome",
          },
        },
        {
          key: "contrapartida",
          name: "Contrapartida",
          colSize: 4,
          hideInform: true,
          type: this.$fieldTypes.SELECT,
          rel: { to: 'contrapartidas', key: "id", name: "value" },
        },
      ]
    },
    projetoTitle() {
      return this.$route.params.projeto?.titulo;
    },
    projetoId() {
      return this.$route.params.id;
    },
    customResource() {
      const resource = this.apiResource(`/v1/captacao/${this.clientId}/dispendios/${this.projetoId}`);
      const that = this;
      return {
        ...resource,
        get(...params) {
          return resource.get(...params).then((result) => {
            that.opts.rubricas = result.rubricas || [];
            that.opts.projetos = result.projetos || [];
            that.opts.fornecedores = result.fornecedores || [];
            that.opts.documentos = result.documentos || [];
            that.opts.descricaoRubricas = result.descricoesRubrica || [];
            that.opts.fornecedores.unshift({
              id: -1,
              razao_social: 'Criar um fornecedor',
              cnpj: 'Criar um fornecedor'
            })
            return result.dispendios || [];
          })
        },
        save(...params) {
          let dados = params[0];
          let isViagensDiarias = that.isVisibleViagensDiarias;
          dados['empresa_id'] = that.clientId;
          dados['projeto_id'] = that.projetoId;

          dados['fornecedor'] = {
            id: !that.isVisibleNewFornecedorData ? dados.fornecedor_id : null,
            cnpj: !that.isVisibleNewFornecedorData ? dados.cnpj : dados.cnpj_novo_fornecedor,
            razao_social: !that.isVisibleNewFornecedorData ? dados.razao_social : dados.razao_social_novo_fornecedor,
          }

          if (isViagensDiarias) {
            that.criaObjetoViagensDiarias(dados);
          }

          return resource.save(dados).then((result) => {
            that.resetModalData();
            return result;
          })
        }
      }
    }
  },
  // filters: {},
  created() {
    this.getProjetoSelecionado();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    updateRubricaDescricao(rubricaId) {
      if (!rubricaId) return this.isDescricaoEditable = false;
      const rubricaSelecionada = this.opts.rubricas.filter((rubrica) => { return rubrica.id == rubricaId })[0];
      if (rubricaSelecionada.tipo == tipoRubricasCamposAdicionaisEnum[0]) this.isVisibleViagensDiarias = true;
      const that = this;
      const resource = this.apiResource(`/v1/captacao/${this.clientId}/rubricas/pareamento/${this.projetoId}/${rubricaId}`);
      return resource.get().then((descricoes) => {
        that.opts.descricaoRubricas = descricoes || [];
        this.isDescricaoEditable = true;
        return descricoes;
      })
    },
    updateFornecedor(fornecedorId) {
      if (fornecedorId !== -1) return this.isVisibleNewFornecedorData = false;
      this.isVisibleNewFornecedorData = true;
    },
    criaObjetoViagensDiarias(dados) {
      return dados['viagensDiarias'] = {
        funcionario: dados.viagens_diarias_nome_funcionario,
        equipeExecutora: dados.viagens_diarias_equipe_executora,
        motivo: dados.viagens_diarias_motivo,
        dataInicio: dados.viagens_diarias_data_inicio,
        dataTermino: dados.viagens_diarias_data_fim,
        origem: dados.viagens_diarias_origem,
        destino: dados.viagens_diarias_destino,
        tipoDespesa: dados.viagens_diarias_tipo_despesa,
        esp: dados.viagens_diarias_esp,
        nroDocumento: dados.viagens_diarias_nro_documento,
        dataDocumento: dados.viagens_diarias_data,
        valorDocumento: dados.viagens_diarias_valor,
      }
    },
    resetModalData() {
      this.isVisibleNewFornecedorData = false;
      this.isVisibleViagensDiarias = false;
      this.formModalOpen = false;
      this.formModalData = {};
      this.$refs.masterDetail.doLoad();
    },
    getProjetoSelecionado() {
      return this.apiResource(`/v1/captacao/projetos/${this.clientId}/${this.projetoId}`).get().then((result) => {
        return this.projeto = result;
      });
    },
  },
  watch: {
    clientId() {
      this.$router.push({ name: 'projetos-captacao' });
    }
  },
}
</script>

<style></style>